import React, { FC, ReactElement } from 'react';

import IconCustom from 'common/IconCustom';
import Title from 'common/Title';

import './FiltersPanelHeader.scss';
import './ShadowFiltersPanelHeader.scss';
import { IPropsFiltersPanelHeader } from './models';

const FiltersPanelHeader: FC<IPropsFiltersPanelHeader> = ({
  closeBtnData,
  clearAllBtnData,
  handleSidebarOpen,
  handleResetSelectionData,
  title,
}): ReactElement | null => (
  <div data-test="FiltersPanelHeader" className="filter-panel-header">
    <div className="filter-panel-header__top">
      <div className="filter-panel-header__top-info">
        <IconCustom icon="filter" />
        <Title dataTestAttr="FilterPanelTitle" Tag="h3">
          {title}
        </Title>
      </div>
      <div className="filter-panel-header__top-action">
        {closeBtnData ? (
          <button
            className="close-btn"
            type="button"
            aria-label={closeBtnData.ariaLabel}
            onClick={handleSidebarOpen}
          >
            <IconCustom icon="cross" />
          </button>
        ) : null}
      </div>
    </div>

    <div className="filter-panel-header__bottom">
      {clearAllBtnData ? (
        <button
          className="clear-btn"
          aria-label={clearAllBtnData.ariaLabel}
          type="button"
          onClick={handleResetSelectionData}
        >
          {clearAllBtnData.label}
        </button>
      ) : null}
    </div>
  </div>
);

export default FiltersPanelHeader;
