import React, { FC, ReactElement } from 'react';

import Button from 'common/Button';

import './FiltersPanelFooter.scss';
import { IPropsFiltersPanelFooter } from './models';

const FiltersPanelFooter: FC<IPropsFiltersPanelFooter> = ({
  showResultsBtnData,
  showResultsBtnTitleSecondary,
  showResultsBtnTitleSecondaryMultiple,
  handleShowResults,
  filtersNumber,
}): ReactElement | null => (
  <div data-test="FiltersPanelFooter" className="filter-panel-footer">
    {showResultsBtnData ? (
      <Button
        clickHandler={handleShowResults}
        ariaLabel={showResultsBtnData.ariaLabel}
        className="show-results-btn"
        textAlign="center"
      >
        {showResultsBtnData.label}
        {filtersNumber ? (
          <span className="filter-panel-footer__number">{`(${filtersNumber})`}</span>
        ) : (
          ' '
        )}
        {filtersNumber > 1 ? showResultsBtnTitleSecondaryMultiple : showResultsBtnTitleSecondary}
      </Button>
    ) : null}
  </div>
);

export default FiltersPanelFooter;
