import React, { FC, ReactElement } from 'react';
import classNames from 'classnames';

import IconCustom from 'common/IconCustom';

import './CustomAccordionHeader.scss';
import { IPropsCustomAccordionHeader } from './model';

const CustomAccordionHeader: FC<IPropsCustomAccordionHeader> = (props): ReactElement => {
  const { header, className, wrapperClassNames, headerRef } = props;

  return (
    <div
      className={classNames('custom-accordion-header-holder', {
        [wrapperClassNames || '']: wrapperClassNames,
      })}
      ref={headerRef}
    >
      <button type="button" className="custom-accordion-header">
        <span
          className={classNames('custom-accordion-header--text', {
            [className || '']: className,
          })}
        >
          {header}
        </span>
        <IconCustom icon="arrow" />
      </button>
    </div>
  );
};

export default CustomAccordionHeader;
