import ProductsListPage from 'gatsby-theme-husky/src/templates/ProductsListPage/ProductsListPage';
import { graphql } from 'gatsby';

export const query = graphql`
  fragment FragmentProductCardAU on Product {
    link
    pageId
    title
    productType
    sku
    id
    defaultProductImage {
      ...FragmentGatsbyProps
      gatsbyImage {
        childImageSharp {
          fluid(maxWidth: 300, sizes: "(max-width: 1023px) calc(50vw - 84px), 370px") {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      alt
    }
  }
  query ($link: String!, $lang: String!, $productsLinks: [String]) {
    pageData: productsList(link: { eq: $link }) {
      seo {
        ...FragmentSeo
      }
      defaultCompositions {
        ...FragmentDefaultCompositions
      }
      title
      titleImage {
        ...FragmentGatsbyImage
      }
      titleImageAlt
      sorryMessage
      showMoreCtaButton {
        ...FragmentButton
      }
      limitOnPageDesktop
      limitOnPageMobile
      filtersStructure {
        ...FragmentFiltersStructure
      }
      warningMessage
      startQuiz {
        ...FragmentStartQuizSection
      }
      buyPromoBanner {
        ...FragmentBuyPromoBanner
      }
      sortingProducts {
        ...FragmentSortingProducts
      }
    }
    defaultProducts: allProduct(filter: { link: { in: $productsLinks }, lang: { eq: $lang } }) {
      nodes {
        ...FragmentProductCardAU
      }
    }
  }
`;

export default ProductsListPage;
