import React, { FC, ReactElement } from 'react';

import IconCustom from 'common/IconCustom';

import './FiltersToggler.scss';
import './ShadowFiltersToggler.scss';
import { IPropsFiltersToggler } from './models';

const FiltersToggler: FC<IPropsFiltersToggler> = ({
  filtersNumber,
  titleMain,
  titleSecondary,
  handleSidebarOpen,
  buttonAriaLabel,
}): ReactElement | null => (
  <div data-test="FiltersToggler" className="filters-toggler">
    <button
      className="filters-toggler__toggle-btn"
      onClick={handleSidebarOpen}
      type="button"
      aria-label={buttonAriaLabel}
    >
      <span className="filters-toggler__info-block">
        <IconCustom icon="filter" />
        <span className="toggle-btn-title">{titleMain}</span>
      </span>

      {filtersNumber ? (
        <span className="filters-toggler__filters-number">
          <span>{filtersNumber}</span>
          <span>{titleSecondary}</span>
        </span>
      ) : null}
    </button>
  </div>
);

export default FiltersToggler;
