import React, { createRef, FC, RefObject, useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import Accordion from 'react-tiny-accordion';

import { isBrowser } from 'utils/browser';
import { ELEMENTS } from 'utils/constants';

import CustomAccordionHeader from 'common/CustomAccordion/CustomAccordionHeader';
import DangerouslySetInnerHtml from 'common/DangerouslySetInnerHtml';

import { ICustomAccordionItem, IPropsCustomAccordion } from './model';
import './CustomAccordion.scss';

const OpenClassName = 'is-open';

const CustomAccordion: FC<IPropsCustomAccordion> = (props) => {
  const {
    items,
    selectedIndex,
    onChange,
    changeOnClick,
    headerClassName,
    wrapperHeaderClassNames,
    scrollTabIntoView,
    isSmallDevice,
  } = props;

  const [tabsHeaderRef, setTabsHeaderRef] = useState<RefObject<HTMLDivElement>[] | []>([]);
  const [tabsContentRef, setTabsContentRef] = useState<RefObject<HTMLDivElement>[] | []>([]);
  const [panels, setPanels] = useState<ICustomAccordionItem[]>();

  const header: HTMLElement | null = isBrowser()
    ? document.getElementById(ELEMENTS.HEADER_ID)
    : null;
  const headerHeight: number = header ? header.clientHeight : 0;

  const onChangeCallback = useCallback(
    (index: number, expanded: boolean, selectedCallbackIndex: number) => {
      if (onChange) {
        onChange({ index, expanded, selectedIndex: selectedCallbackIndex });
      }

      if (scrollTabIntoView && panels?.[selectedCallbackIndex]?.offset) {
        window.scrollTo({
          top: panels[selectedCallbackIndex].offset,
          behavior: isSmallDevice ? 'auto' : 'smooth',
        });
      }
    },
    [tabsHeaderRef, panels]
  );

  useEffect(() => {
    const headersRefs: RefObject<HTMLDivElement>[] = items.map(() => createRef());
    setTabsHeaderRef(headersRefs);
    const contentRefs: RefObject<HTMLDivElement>[] = items.map(() => createRef());
    setTabsContentRef(contentRefs);
  }, []);

  useEffect(() => {
    scrollTabIntoView &&
      setTimeout(
        () => {
          const accordionPanels = items.map((panel, i) => {
            const topOffset = tabsHeaderRef?.[i]?.current?.getBoundingClientRect().top || 0;
            const openedTabHeight =
              panel.isOpened === '1'
                ? 0
                : tabsContentRef.filter((tabContent) =>
                    tabContent.current?.classList.contains(OpenClassName)
                  )?.[0]?.current?.clientHeight || 0;

            const offset = topOffset + window.pageYOffset - openedTabHeight - headerHeight;

            return {
              ...panel,
              offset,
            };
          });
          setPanels(accordionPanels);
        },
        isSmallDevice ? 250 : 0
      );
  }, [tabsHeaderRef, tabsContentRef]);

  useEffect(() => {
    !scrollTabIntoView && setPanels(items);
  }, [items]);

  return panels?.length ? (
    <div className="custom-accordion" data-test="CustomAccordion">
      <Accordion
        openClassName="open"
        selectedIndex={selectedIndex}
        transitionDuration={scrollTabIntoView ? (isSmallDevice ? 0 : 200) : 200}
        onChange={onChangeCallback}
        changeOnClick={changeOnClick}
        data-test="Accordion"
      >
        {panels.map((item: ICustomAccordionItem, i) =>
          item.content && item.header ? (
            <div
              key={item.key}
              className="custom-accordion--header-wrapper"
              data-header={
                <CustomAccordionHeader
                  header={item.header}
                  className={classNames({ [headerClassName || '']: headerClassName })}
                  wrapperClassNames={wrapperHeaderClassNames}
                  headerRef={tabsHeaderRef[i]}
                />
              }
            >
              <div
                ref={tabsContentRef[i]}
                className={classNames('custom-accordion--content', {
                  [OpenClassName]: item.isOpened === '1',
                })}
              >
                {typeof item.content === 'string' ? (
                  <DangerouslySetInnerHtml html={item.content} />
                ) : (
                  item.content
                )}
              </div>
            </div>
          ) : null
        )}
      </Accordion>
    </div>
  ) : null;
};

export default CustomAccordion;
