import React, { FC } from 'react';
import { graphql } from 'gatsby';

import GatsbyImage from 'common/GatsbyImage';
import RelatedCarousel from 'components/RelatedCarousel';
import StoreCard from 'components/StoreCard';
import Button from 'common/Button';
import Container from 'layout/Container';

import useScreenRecognition from 'hooks/useScreenRecognition';

import { IPropsBuyPromoBanner } from './models';
import './BuyPromoBanner.scss';
import './ShadowBuyPromoBanner.scss';

const BuyPromoBanner: FC<IPropsBuyPromoBanner> = ({
  buyPromoBanner: [
    {
      properties: { buttonTitle, galleryTitle, button, gallery, backgroundImg, backgroundImgAlt },
    },
  ],
  ariaLabelPrev,
  ariaLabelNext,
  dir,
}) => {
  const { isTablet, isDesktop } = useScreenRecognition();
  const slidesPerView = isDesktop || isTablet ? (isTablet ? 2 : 3) : 1.4;
  const buttonProps = button?.[0]?.properties;

  return (
    <div className="buy-promo-banner">
      {backgroundImg ? (
        <GatsbyImage
          className="background"
          image={backgroundImg}
          alt={backgroundImgAlt}
          objectFit="contain"
          objectPosition="100% 0%"
        />
      ) : null}
      <Container className="buy-promo-banner__container">
        <div className="buy-promo-banner__left">
          <h2 className="buy-promo-banner__left__title">{galleryTitle}</h2>
          {gallery?.length && (
            <RelatedCarousel
              slidesPerView={slidesPerView}
              ariaLabelPrev={ariaLabelPrev}
              ariaLabelNext={ariaLabelNext}
              isDesktop={Boolean(isDesktop)}
              dir={dir}
              isShowPagination={gallery.length > slidesPerView}
              isShowNavigation={gallery.length > slidesPerView}
              loop={slidesPerView < gallery.length}
            >
              {gallery.map(({ properties: { altLabel, image, link, cardPrefix } }) => (
                <StoreCard
                  key={altLabel}
                  image={image}
                  altLabel={altLabel}
                  link={link}
                  cardPrefix={cardPrefix}
                />
              ))}
            </RelatedCarousel>
          )}
        </div>
        {buttonTitle || buttonProps ? (
          <div className="buy-promo-banner__right">
            <div className="buy-promo-banner__right__holder">
              <h2 className="buy-promo-banner__right__title">{buttonTitle}</h2>
              {buttonProps?.link?.[0].url ? (
                <Button
                  className="buy-promo-banner__right__button"
                  ariaLabel={buttonProps.ariaLabel}
                  link={buttonProps.link[0].url}
                >
                  {buttonProps.label}
                </Button>
              ) : null}
            </div>
          </div>
        ) : null}
      </Container>
    </div>
  );
};

export const query = graphql`
  fragment FragmentBuyPromoBanner on TBuyPromoBanner {
    properties {
      button {
        ...FragmentButton
      }
      buttonTitle
      gallery {
        properties {
          altLabel
          image {
            ...FragmentGatsbyImage
          }
          link {
            url
            target
            queryString
          }
        }
      }
      galleryTitle
      backgroundImg {
        ...FragmentGatsbyImage
      }
      backgroundImgAlt
    }
  }
`;

export default BuyPromoBanner;
