import React, { FC } from 'react';
import classNames from 'classnames';

import DangerouslySetInnerHtml from 'common/DangerouslySetInnerHtml';

import './CheckBoxCustom.scss';
import { IPropsCheckBoxCustom } from './models';

const CheckBoxCustom: FC<IPropsCheckBoxCustom> = ({
  isChecked,
  isDisabled,
  id,
  name,
  labelText,
  onChange,
}) => (
  <div
    data-test="CheckBoxCustom"
    className={classNames('checkbox-custom', {
      'checkbox-custom--disabled': isDisabled,
    })}
  >
    <input
      className="checkbox-custom__input"
      type="checkbox"
      checked={isChecked}
      onChange={onChange}
      id={`checkbox-custom-${id}`}
      name={name}
    />
    <label htmlFor={`checkbox-custom-${id}`}>
      <span className="checkbox-custom__fake-input">
        <span className="checkbox-custom__fake-input__sign" />
      </span>
      <DangerouslySetInnerHtml html={labelText} className="checkbox-custom__fake-label" />
    </label>
  </div>
);

export default CheckBoxCustom;
