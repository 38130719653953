import React, { FC, ReactElement, useCallback, useRef } from 'react';

import classNames from 'classnames';

import useClickOutside from 'hooks/useClickOutside';
import { scrollToTarget } from 'utils/browser';
import { parseButtonComposition } from 'utils/parsers';

import FiltersPanelHeader from 'components/AlgoliaFilters/FiltersPanelHeader';
import FiltersPanelFooter from 'components/AlgoliaFilters/FiltersPanelFooter';
import FiltersPanelContent from 'components/AlgoliaFilters/FiltersPanelContent';

import './FilterPanel.scss';
import './ShadowFilterPanel.scss';
import { IPropsFilterPanel } from './model';

const FilterPanel: FC<IPropsFilterPanel> = ({
  isSidebarOpen,
  handleSidebarOpen,
  filtersStructureData,
  isSmallDevice,
  showResultsScrollTargetId,
  handleResetSelectionData,
  handleRemoveSelectionData,
  chosenFilterIds,
  filterSections,
  filtersNumber,
}): ReactElement | null => {
  const wrapperRef = useRef(null);

  const handleCloseSidebar = useCallback(() => {
    if (!isSidebarOpen) {
      return;
    }
    handleSidebarOpen();
  }, [isSidebarOpen]);

  useClickOutside(wrapperRef, handleCloseSidebar);

  const handleShowResults = useCallback(() => {
    handleSidebarOpen();
    scrollToTarget(showResultsScrollTargetId, 5);
  }, []);

  const handleOnBlur = useCallback(
    (event: React.FocusEvent<HTMLUListElement | any>) => {
      if (!event.currentTarget.contains(event.relatedTarget)) {
        handleCloseSidebar();
      }
    },
    [handleCloseSidebar]
  );

  return (
    <div
      data-test="FilterPanel"
      // eslint-disable-next-line jsx-a11y/tabindex-no-positive, jsx-a11y/no-noninteractive-tabindex
      tabIndex={1}
      onBlur={handleOnBlur}
      className={classNames('filter-panel', {
        open: isSidebarOpen,
      })}
    >
      <div className="filter-panel__slide">
        <div className="cover" />
        <div className="filter-panel-menu" ref={wrapperRef}>
          <div className="filter-panel-menu__wrapper">
            <FiltersPanelHeader
              title={filtersStructureData.title}
              closeBtnData={parseButtonComposition(filtersStructureData.closeButton)}
              clearAllBtnData={parseButtonComposition(filtersStructureData.clearAllButton)}
              handleSidebarOpen={handleSidebarOpen}
              handleResetSelectionData={handleResetSelectionData}
            />
            <FiltersPanelContent
              filterSections={filterSections}
              handleRemoveSelectionData={handleRemoveSelectionData}
              chosenFilterIds={chosenFilterIds}
              isSmallDevice={isSmallDevice}
            />
            <FiltersPanelFooter
              showResultsBtnData={parseButtonComposition(filtersStructureData.showResultsButton)}
              showResultsBtnTitleSecondary={filtersStructureData.showResultsButtonTitleSecondary}
              showResultsBtnTitleSecondaryMultiple={
                filtersStructureData.showResultsButtonTitleSecondaryMultiple
              }
              handleShowResults={handleShowResults}
              filtersNumber={filtersNumber}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterPanel;
