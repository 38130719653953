import React, { FC, ReactElement } from 'react';

import Container from 'layout/Container';

import Title from 'common/Title';
import GatsbyImage from 'common/GatsbyImage';

import './TitleWithImage.scss';
import { IPropsTitleWithImage } from './models';

const TitleWithImage: FC<IPropsTitleWithImage> = ({
  title,
  titleImage,
  titleImageAlt,
  id,
}): ReactElement | null => (
  <Container fluid>
    <div data-test="TitleWithImage" className="title-with-image" {...(id ? { id } : {})}>
      <Title dataTestAttr="titleWithImage" Tag="h1">
        {title}
      </Title>
      {titleImage && titleImageAlt ? (
        <div className="title-image-wrapper">
          <GatsbyImage
            image={titleImage}
            alt={titleImageAlt}
            className="title-image"
            isLazyLoading={false}
            fadeIn={false}
          />
        </div>
      ) : null}
    </div>
  </Container>
);

export default TitleWithImage;
