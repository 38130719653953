import React, { FC, ReactElement } from 'react';

import ConnectedCustomRefinementList from 'components/AlgoliaFilters/CustomRefinementList';

import './FiltersPanelContent.scss';
import './ShadowFiltersPanelContent.scss';
import { IPropsFiltersPanelContent } from './models';

const FiltersPanelContent: FC<IPropsFiltersPanelContent> = ({
  filterSections,
  chosenFilterIds,
  handleRemoveSelectionData,
  isSmallDevice,
}): ReactElement | null => (
  <div data-test="FiltersPanelContent" className="filter-panel-content">
    {filterSections?.length
      ? filterSections.map(
          ({ attributeForFaceting, filterItems, isOpen, title, isCheckedAndHidden }) => (
            <ConnectedCustomRefinementList
              key={title}
              attribute={attributeForFaceting}
              filterItems={filterItems}
              sectionId={attributeForFaceting}
              isOpen={isOpen === '1'}
              title={title}
              chosenFilterIds={chosenFilterIds}
              handleRemoveSelectionData={handleRemoveSelectionData}
              isSmallDevice={isSmallDevice}
              isCheckedAndHidden={isCheckedAndHidden}
            />
          )
        )
      : null}
  </div>
);

export default FiltersPanelContent;
